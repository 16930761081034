$utilities: map-merge(
  $utilities, (
    "font-size": map-merge(
      map-get($utilities, "font-size"),
      ( 
        responsive: true,
        values: map-merge(
          map-get(map-get($utilities, "font-size"), "values"),
          (
            "8": 8px,
            "10": 10px,
            "12": 12px,
            "15": 15px,
            "14": 14px,
            "16": 16px,
            "18": 18px,
            "20": 20px,
            "24": 24px,
            "28": 28px,
            "36": 36px,
            "40": 40px,
            "48": 48px,
            "80": 80px,
            ),
        ),
      ),
    ),

    "line-height": map-merge(
      map-get($utilities, "line-height"),
      ( 
        responsive: true,
        values: map-merge(
          map-get(map-get($utilities, "line-height"), "values"),
          (
            "12": 12px,
            "14": 14px,
            "16": 16px,
            "18": 18px,
            "20": 20px,
            "22": 22px,
            "24": 24px,
            "28": 28px,
            "40": 40px,
            "80": 80px,
          ),
        ),
      ),
    ),

    "font-weight": map-merge(
      map-get($utilities, "font-weight"),
      ( 
        values: map-merge(
          map-get(map-get($utilities, "font-weight"), "values"),
          (
            "100": 100,
            "200": 200,
            "300": 300,
            "400": 400,
            "500": 500,
            "600": 600,
            "700": 700,
            "800": 800,
            "900": 900
          ),
        ),
      ),
    ),

    "border": map-merge(
      map-get($utilities, "border"),
      ( responsive: true ),
    ),

    "rounded": map-merge(
      map-get($utilities, "rounded"),
      ( 
        values: map-merge(
          map-get(map-get($utilities, "rounded"), "values"),
          (
            6: 6px,
            8: 8px,
            16: 16px,
          ),
        ),
      ),
    ),
  )
);

.transition-03 {
  transition: all .3s;
}

.cursor-pointer {
  cursor: pointer;
}

.avatar-32 {
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
}
.text-truncate-1{
  -webkit-line-clamp: 1;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.search-participant-input {
  width: 1200px;
  border-radius: 64px;
  border: 1px solid var(--Glass_stroke, rgba(255, 255, 255, 0.50));
  background: var(--Glass_fill, linear-gradient(92deg, rgba(255, 255, 255, 0.16) 1.66%, rgba(255, 255, 255, 0.08) 101.44%));
  box-shadow: 0px 0px 48px 0px rgba(255, 255, 255, 0.16) inset;
  backdrop-filter: blur(16px);
  appearance: none;
}
.search-participant-input:focus-visible {
  outline: none;
}
@media (max-width: 1399.98px) {
  .search-participant-input {
    width: 1110px;
  }
}
@media (max-width: 1199.98px) {
  .search-participant-input {
    width: 940px;
  }
}
@media (max-width: 991.98px) {
  .search-participant-input {
    width: 700px;
  }
}
@media (max-width: 767.98px) {
  .search-participant-input {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .search-participant-input {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .modal-500 {
    --bs-modal-width: 400px;
  }
}

.bg-img {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // width: 100vw;
  // height: 100vh;
}
.bg-img-var-1 {
  background-image: url(/img/background_confetti_v.jpg);
}
.bg-img-var-2 {
  background-image: url(/img/background_gifts_v.jpg);
}
.bg-img-var-3 {
  background-image: url(/img/background_ribbon_v.jpg);
}
.bg-img-var-4 {
  background-image: url(/img/background_pattern_v.jpg);
}
.bg-img-var-5 {
  background-image: url(/img/background_limoniya_v2.jpg);
}

.row-custom-vh {
  height: 100vh;
}
@media (max-width: 767.98px) {
  .row-custom-vh {
    height: 90vh;
  }

  .logo-h {
    max-height: 56px;
  }
}

.sp-scrollbar-y {
  overflow-x: hidden;
  overflow-y: auto;
}
.sp-scrollbar-y::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position-x: center;
  // background-image: url(/img/bkg-scrollbar.jpg);
}
.sp-scrollbar-y::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.sp-scrollbar-y::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
.sp-scrollbar-y::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.sp-scrollbar-y-white::-webkit-scrollbar-thumb {
  background-color: var(--bs-white, #FFF);
}
.sp-scrollbar-y-dark::-webkit-scrollbar-thumb {
  background-color: var(--bs-dark-24, #D6D6D6);
}