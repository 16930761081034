.result-container {
  background: linear-gradient(92deg, rgba(255, 255, 255, 0.50) 1.66%, rgba(255, 255, 255, 0.70) 101.44%);
  backdrop-filter: blur(16px);
}

.result-container-position {
  //height: 316px;
  // max-height: 316px;
  width: 100%;
}

@media (min-width: 768px) {
  .result-container-position {
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: 240px;
    //height: 284px;
    // max-height: 284px;
  }
}

.result-container-list {
  //min-height: 280px;
  max-height: 280px;
}
@media (min-width: 768px) {
  .result-container-list {
    //min-height: 246px;
    max-height: 25vh;
  }
}