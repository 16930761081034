.btn-purple-link {
  --bs-btn-color: white;
  --bs-btn-bg: #990099;
  --bs-btn-border-color: #990099;
  --bs-btn-border-radius: .5rem;

  --bs-btn-hover-color: white;
  --bs-btn-hover-bg: #8EC200;
  --bs-btn-hover-border-color: #8EC200;

  --bs-btn-active-color: white;
  --bs-btn-active-bg: #FF0099;
  --bs-btn-active-border-color: #FF0099;
}

.btn-white-purple-link {
  --bs-btn-color: #990099;
  --bs-btn-bg: white;
  --bs-btn-border-color: white;
  --bs-btn-border-radius: .5rem;

  --bs-btn-hover-color: #8EC200;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;

  --bs-btn-active-color: #FF0099;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
}
.btn-white-black-link {
  --bs-btn-color: #000000;
  --bs-btn-bg: white;
  --bs-btn-border-color: white;
  --bs-btn-border-radius: .5rem;

  --bs-btn-hover-color: #990099;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;

  --bs-btn-active-color: #FF0099;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
}