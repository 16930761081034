@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
$font-family-sans-serif: 'Roboto', sans-serif;
// @import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
// $font-family-sans-serif: "PT Serif", serif;

$theme-colors: (
  "primary": #7b1566,
  "secondary": #34495e,
  "success": #6DC344,
  "danger": #da3293,
  "info": #2B86C5,
  "warning": #FFAA00,

  "purple": #990099,
  "magenta": #FF0099,
  "green": #8EC200,
  "red": #FF4747,
  "orange": #FF7700,
  "yellow": #FFAA00,
  "blue": #0088FF,
  "dark": #00101E,
  "black": #000000, 
  "white": #FFFFFF,
);

$custom-colors: (
  //Dark или серый
  "dark-12": #F5F5F5,
  "dark-24": #D6D6D6,
  "dark-60": #7A7A7A,
);

// Custom spacers
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,

  ad1: ($spacer * .0625),
  ad2: ($spacer * .125),
  ad3: ($spacer * .1875),
  ad4: ($spacer * .25),
  ad6: ($spacer * .375),
  ad8: ($spacer * .5),
  ad10: ($spacer * .625),
  ad12: ($spacer * .75),
  ad14: ($spacer * .875),
  ad15: ($spacer * .9375),
  ad16: ($spacer * 1),
  ad18: ($spacer * 1.125),
  ad20: ($spacer * 1.25),
  ad22: ($spacer * 1,375),
  ad24: ($spacer * 1.5),
  ad28: ($spacer * 1.75),
  ad30: ($spacer * 1.875),
  ad32: ($spacer * 2),
  ad34: ($spacer * 2.125),
  ad36: ($spacer * 2.25),
  ad40: ($spacer * 2.5),
  ad44: ($spacer * 2.75),
  ad48: ($spacer * 3),
  ad52: ($spacer * 3.25),
  ad56: ($spacer * 3.5),
  ad60: ($spacer * 3.75),
  ad64: ($spacer * 4),
  ad68: ($spacer * 4.25),
  ad72: ($spacer * 4.5),
  ad76: ($spacer * 4.75),
  ad80: ($spacer * 5),
  ad120: ($spacer * 7.5)
);

@import "bootstrap/scss/mixins/banner";
@include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Customs scss
@import "src/scss/custom_bootstrap";
@import "src/scss/custom_buttons";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
