.modal-content {
  --bs-modal-border-radius: 16px;
}
.form-setting-style:hover {
  border: 1px solid var(--bs-dark-24, #D6D6D6) !important;
}
.form-setting-style:focus {
  border: 1px solid var(--bs-purple, #909) !important;
  background: var(--bs-white, #FFF) !important;
  box-shadow: none;
}

.form-select-setting {
  background-size: 16px 16px;
  color: var(--bs-dark-60, #7A7A7A) !important;
  --bs-form-select-bg-img: url(/img/arrow_down.svg)
}

.form-select-setting:hover {
  cursor: pointer;
  border: 1px solid var(--bs-dark-24, #D6D6D6) !important;
}
.form-select-setting:focus {
  border: 1px solid var(--bs-purple, #909) !important;
  background-color: var(--bs-white, #FFF) !important;
  box-shadow: none;
  --bs-form-select-bg-img: url(/img/arrow_up.svg)
}

.form-check-input:hover {
  cursor: pointer;
  border-color: var(--bs-purple, #909);
}
.form-check-input:focus {
  box-shadow: none;
  border-color: var(--bs-purple, #909);
}
.form-check-input:checked {
  background-color: var(--bs-white, #FFF) !important;
  border-color: var(--bs-green, #8EC200) !important;
  background-image:url(/img/checkbox_green.svg);
}
.form-check-input {
  width: 24px;
  height: 24px;
  margin-top: 0;
}
.form-check-label {
  cursor: pointer;
}

.user-edit-tabs-btn {
  color: var(--bs-dark-60, #7A7A7A) ;
  background-color: transparent;
  border-radius: 6px;
  width: auto;
  max-width: 100%;
}
.user-edit-tabs-btn:hover {
  color: var(--bs-black, #000) !important;
}
.user-edit-tabs-btn.active {
  color: var(--bs-black, #000);
  background-color: var(--bs-white, #fff) ;
  z-index: 1;
}

.modal-setting-list {
  min-height: 150px !important;
  max-height: 160px !important;
  resize: none;
}